// React
import { useContext } from 'react'
import { StateContext } from '../App'

// Localization
import { FormattedMessage } from 'react-intl'

// Button
import Button from 'react-bootstrap/Button'

// Functions
import { imageTypes } from '../utils/functions'

// Styling
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import '../styles/Kuvatyypit.scss'

const Kuvatyypit = (props) => {
  const { lang } = props
  const ctx = useContext(StateContext)
  const state = ctx[0]
  const setState = ctx[1]
  let allTypesAreUnselected = state?.type?.length > 0 ? false : true

  const toggleAll = () => {
    if (allTypesAreUnselected) {
      setState({
        ...state,
        arasaac: true,
        drawing: true,
        kuvako: true,
        mulberry: true,
        photo: true,
        sclera: true,
        sign: true,
        toisto: true,
        viittomavideo: true
      })
    } else {
      setState({
        ...state,
        arasaac: false,
        drawing: false,
        kuvako: false,
        mulberry: false,
        photo: false,
        sclera: false,
        sign: false,
        toisto: false,
        viittomavideo: false
      })
    }
  }

  return (
    <div className='Kuvatyypit'>
      <h2>
        <FormattedMessage id='Kuvatyypit.valitse' />
      </h2>
      <Button className='typeToggler' variant='link' onClick={() => toggleAll()}>
        {allTypesAreUnselected ?
        <FormattedMessage id='Kuvatyypit.toggleAllOn' />
        :
        <FormattedMessage id='Kuvatyypit.toggleAllOff' />
        }
      </Button>
      <div className='KuvatyypitButtons'>
        {imageTypes.map((el) => (
          <Button
            role='checkbox'
            aria-checked={state[el.id]}
            key={el.id}
            hidden={lang === 'se' && el.id === 'viittomavideo'}
            onClick={() => setState({ ...state, [el.id]: !state[el.id] })}>
            {state[el.id] ? (
              <ImCheckboxChecked className='checkBox' />
            ) : (
              <ImCheckboxUnchecked className='checkBox' />
            )}
            <span>{lang === 'fi' ? el.label_fi : el.label_se}</span>
            <img
              src={el.img}
              style={{ borderRadius: '6px' }}
              id='KuvatyyppiKuva'
              alt=''
              width='64'
              height='64'
            />
          </Button>
        ))}
      </div>
    </div>
  )
}

export default Kuvatyypit
