// Routing
import { Link } from 'react-router-dom'

// Localization
import { FormattedMessage } from 'react-intl'

function NotFound() {
  return (
    <div style={{ backgroundColor: 'white', width: '100%', padding: '30px' }}>
      <h2 style={{ paddingBottom: '20px' }}>
        <FormattedMessage id='NotFound.otsikko' />
      </h2>
      <Link to='/'>
        <FormattedMessage id='NotFound.takaisin' />
      </Link>
    </div>
  )
}

export default NotFound
