// React
import { useEffect, useState } from 'react'

// Routing
import { useLocation, useParams } from 'react-router-dom'

// Localization
import { FormattedMessage } from 'react-intl'

// Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react'

// Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

// Components
import Valilehdet from '../components/Valilehdet'
import Kuvat from '../components/Kuvat'
import Kategoriat from '../components/Kategoriat'
import Kuvatyypit from '../components/Kuvatyypit'
import KuvatyypitMobiili from '../components/KuvatyypitMobiili'

// Functions
import { fetchData } from '../utils/functions'

// Viittomavideoiden data
const jsonViittomavideot = require('../utils/viittomavideot.json')

const SelaaKuvia = (props) => {
  const { updateSelaaURL, lang } = props
  const location = useLocation()
  const params = useParams()
  const { trackEvent } = useMatomo()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [title, setTitle] = useState('')
  const [prevLocation, setPrevLocation] = useState('')

  const tuoViittomavideot = (data) => {
    let path = decodeURI(data.path)
    if (path.includes('/')) {
      let i = path.indexOf('/')
      path = path.slice(i + 1)
    }
    const matchesFromVideos = jsonViittomavideot.filter((vid) =>
      vid.categories.some((category) => category.toLowerCase().includes(path.toLowerCase()))
    )
    if (matchesFromVideos && matchesFromVideos.length) {
      data.images = data.images.concat(matchesFromVideos)
      data.images.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    }
    return data
  }

  // Ei käytössä missään juuri nyt
  // const tuoKaikkiViittomavideot = (data) => {
  //   data.images = data.images.concat(jsonViittomavideot)
  //   data.images.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
  //   return data
  // }

  useEffect(() => {
    if (location.pathname !== prevLocation) {
      setPrevLocation(location.pathname)
      updateSelaaURL(location.pathname)
      setLoading(true)
      fetchData('selaa', lang, '', params.cat1, params.cat2).then((res) => {
        if (res.success) {
          if (params.cat2) {
            setTitle(params.cat2)
            trackEvent({ category: 'Navigointi', action: `Kuvaluokka (${params.cat2})` })
          } else if (params.cat1) {
            setTitle(params.cat1)
            trackEvent({ category: 'Navigointi', action: `Kuvaluokka (${params.cat1})` })
          } else {
            setTitle(<FormattedMessage id='Selaa.kategoriat' />)
          }
          if (!res.data.path || (res.data.path && res.data.children.length)) {
            // path on tyhjä --> kategoria painikkeet
            // path ja children on --> alakategoria painikkeet
            setData(res.data)
          } else {
            // kategoria on valittu --> tuodaan osuvat viittomavideot mukaan
            setData(tuoViittomavideot(res.data))
          }
          setLoading(false)
        } else {
          setTitle('')
          setError(res.error)
          setLoading(false)
        }
      })
    }
    //eslint-disable-next-line
  }, [params])

  if (error) {
    return <p>Virhe: {error}</p>
  } else {
    return (
      <Container fluid className='Main'>
        <Valilehdet />
        <Container fluid className='Content'>
          <Row>
            <Col xs={12} xl={9} className='ContentCol'>
              <Kuvatyypit lang={lang} />
              <KuvatyypitMobiili lang={lang} />
              <h2>{title}</h2>
              {!loading && data && data.children && data.children.length ? (
                <Kategoriat children={data && data.children ? data.children : []} categoryDetails={data && data.categoryDetails ? data.categoryDetails : []} params={params} />
              ) : (
                <Kuvat page='selaa' images={data && data.images ? data.images : []} />
              )}
              {loading ? (
                <Spinner animation='border' role='status' style={{ margin: '15px auto' }}>
                  <span className='sr-only'>Ladataan kuvia...</span>
                </Spinner>
              ) : null}
            </Col>
            <Col xs={12} xl={3} className='KuvatyypitCol'>
              <Kuvatyypit lang={lang} />
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default SelaaKuvia
