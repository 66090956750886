// React
import { useContext, useEffect, useState } from 'react'
import { StateContext } from '../App'

// Routing
import { Link, useParams } from 'react-router-dom'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react'

// Bootstrap
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'

// Styling
import { FaArrowLeft, FaArrowRight, FaSearch } from 'react-icons/fa'
import { AiOutlineYoutube } from 'react-icons/ai'
import '../styles/Kuvat.scss'
import '../styles/Modal.scss'

const Kuvat = (props) => {
  const params = useParams()
  const ctx = useContext(StateContext)
  const intl = useIntl()
  const { trackEvent } = useMatomo()
  const state = ctx[0]
  const { type } = state
  const { page, images } = props

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filteredImagesForModal, setFilteredImagesForModal] = useState(images)
  const [selectedCard, setSelectedCard] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [nrOfPages, setNrOfPages] = useState(0)
  const [paginationInfo, setPaginationInfo] = useState([])
  const [buttons, setButtons] = useState(null)
  const [totalImages, setTotalImages] = useState(0)

  const initialSortMethod = page === 'selaa' ? 'alphabet' : 'score'
  const [sortMethod, setSortMethod] = useState(initialSortMethod)

  const cardsPerPage = 80
  const maxPages = 15

  const firstImg = Array.from({ length: maxPages }, (_, i) => 1 + i * cardsPerPage)
  const lastImg = Array.from({ length: maxPages }, (_, i) => cardsPerPage * (i + 1))

  const filterAndSortImages = () => {
    let imageSet

    // Suodata kuvatyypit
    if (type.length === 9) {
      imageSet = images
    } else {
      imageSet = images.filter((img) => type.includes(img.type))
    }

    // Järjestä suodatetut kuvat
    if (sortMethod === 'score') {
      imageSet.sort((a, b) => b.score - a.score)
    }
    if (sortMethod === 'alphabet') {
      imageSet.sort((a, b) => a.name.localeCompare(b.name))
    }
    if (sortMethod === 'alphabet-reversed') {
      imageSet.sort((a, b) => b.name.localeCompare(a.name))
    }

    setFilteredImagesForModal(imageSet)
    let buttonElements = imageSet.map((item) => (
      <a
        id={item.uid}
        key={item.uid}
        href='#img'
        onClick={(e) => {
          e.preventDefault()
          cardClick(item.uid, item.name, item.url, item.author, item.type)
        }}>
        <Card>
          <Card.Body>
            {item.type === 'Viittomavideo' ? <AiOutlineYoutube className='playIcon' /> : null}
            <Card.Img src={item.type === 'Viittomavideo' ? item.thumb_large : item.url} alt='' />
          </Card.Body>
          <Card.Body>
            <h3 className='card-title'>{item.name}</h3>
          </Card.Body>
        </Card>
      </a>
    ))
    setButtons(buttonElements)
    setActivePage(1)
    updatePaginationButtons(1)
    setTotalImages(imageSet.length)
    updateNrOfPages(imageSet.length)
  }

  useEffect(() => {
    setLoading(true)
    filterAndSortImages()
    setLoading(false)
    // eslint-disable-next-line
  }, [images, type, sortMethod])

  const updateNrOfPages = (totalImages) => {
    let n = null

    if (totalImages % 80 === 0) {
      n = totalImages / 80
    } else {
      n = totalImages / 80 + 1
    }

    setNrOfPages(Math.trunc(n))
  }

  const setAttributes = () => {
    if (document.getElementsByClassName('fade')[1]) {
      document.getElementsByClassName('fade')[1].setAttribute('aria-hidden', 'false')
      return
    }
  }

  const handleSortMethodChange = (e) => {
    setSortMethod(e.target.value)
    document.getElementById("sort-method").blur()
  }

  const handleShow = () => {
    setShow(true)
    window.setTimeout(setAttributes, 100)
  }

  const handleHide = () => {
    setShow(false)
  }

  const cardClick = (uid, name, url, author, type) => {
    let analyticsInfo = name + ' - ' + url
    let removeSpaces = name.replace(/ /g, '_')
    let removeCommas = removeSpaces.replace(/,/g, '')
    let nameWithExtension

    if (url.substr(-4) === 'jpeg') {
      nameWithExtension = removeCommas + url.substr(-5)
    } else if (type === 'Viittomavideo') {
      nameWithExtension = null
    } else {
      nameWithExtension = removeCommas + url.substr(-4)
    }

    const filenameForDownload = `${name}_${uid}`
    setSelectedCard([uid, name, url, author, nameWithExtension, type, filenameForDownload])
    handleShow()

    trackEvent({ category: 'Kuva', action: `Kuva avattu (${analyticsInfo})` })
  }

  const pageChange = (number, scroll) => {
    if (scroll && activePage !== number) {
      window.scrollTo({
        top: 0,
        left: 0,
      })
    }

    if (number >= 1 && number <= nrOfPages) {
      setActivePage(number)
    }

    updatePaginationButtons(number)
  }

  const updatePaginationButtons = (activePage) => {
    if (nrOfPages === 1) {
      setPaginationInfo([true, 0, 1, 2, 3, 4, true])
    } else if (nrOfPages <= 5) {
      setPaginationInfo([true, 0, 1, 2, 3, 4, true])
    } else {
      if (activePage === 1) {
        setPaginationInfo([true, 0, 1, 2, 3, 4, false])
      }

      if (activePage === 2) {
        const a = activePage
        setPaginationInfo([true, a - 2, a - 1, a, a + 1, a + 2, false])
      }

      if (activePage === 3) {
        const a = activePage
        setPaginationInfo([true, a - 3, a - 2, a - 1, a, a + 1, false])
      }

      if (activePage >= 4) {
        const a = activePage
        const n = nrOfPages

        if (a === n) {
          setPaginationInfo([false, a - 5, a - 4, a - 3, a - 2, a - 1, true])
        } else if (a + 1 >= n) {
          setPaginationInfo([false, a - 4, a - 3, a - 2, a - 1, a, true])
        } else if (a + 2 >= n) {
          setPaginationInfo([false, a - 3, a - 2, a - 1, a, a + 1, true])
        } else {
          setPaginationInfo([false, a - 3, a - 2, a - 1, a, a + 1, false])
        }
      }
    }
  }

  const nextImage = (id, next) => {
    let n = filteredImagesForModal.findIndex((btn) => btn.uid === id)
    let newImage
    if (next) newImage = filteredImagesForModal[n + 1]
    else newImage = filteredImagesForModal[n - 1]

    if (newImage) {
      let removeSpaces = newImage.name.replace(/ /g, '_')
      let removeCommas = removeSpaces.replace(/,/g, '')

      let nameWithExtension
      if (newImage.url.substr(-4) === 'jpeg') {
        nameWithExtension = removeCommas + newImage.url.substr(-5)
      } else if (newImage.type === 'Viittomavideo') {
        nameWithExtension = null
      } else {
        nameWithExtension = removeCommas + newImage.url.substr(-4)
      }

      setSelectedCard([
        newImage.uid,
        newImage.name,
        newImage.url,
        newImage.author,
        nameWithExtension,
        newImage.type,
      ])
    }
  }

  const handleKeyPress = (e, id) => {
    if (e.key === 'ArrowLeft') {
      nextImage(id, false)
    } else if (e.key === 'ArrowRight') {
      nextImage(id, true)
    }
  }

  const getButtons = (start) => {
    return buttons.slice(start, start + cardsPerPage)
  }

  const analyticsClickEvent = (a, b) => {
    trackEvent({ category: 'Kuva', action: `Kuva ladattu (${a}-${b})` })
  }

  useEffect(() => {
    if (!loading) {
      const prevSpans = document.querySelectorAll('#pagination-prev span.visually-hidden')
      prevSpans.forEach((span) => {
        span.textContent = intl.formatMessage({ id: 'Edellinen' })
      })
  
      const nextSpans = document.querySelectorAll('#pagination-next span.visually-hidden')
      nextSpans.forEach((span) => {
        span.textContent = intl.formatMessage({ id: 'Seuraava' })
      })
    }
  // eslint-disable-next-line
  }, [loading, activePage])

  const forceDownload = (blob, filename) => {
    var a = document.createElement('a')
    a.download = filename
    a.href = blob
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
  
  const downloadResource = (url, filename) => {
    if (!filename) filename = url.split('\\').pop().split('/').pop()
    fetch(url, {
        headers: new Headers({
          'Origin': window.location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob)
        forceDownload(blobUrl, filename)
      })
      .catch(e => console.error(e))
  }

  if (loading) {
    return (
      <div style={{ width: '100%' }}>
        <Row>
          <Col xs={12} xl={12} id='ColumnCardNavigation2'></Col>
        </Row>
      </div>
    )
  } else {
    let pages = []
    let pages2 = []

    for (let number = 1; number <= nrOfPages; number++) {
      pages.push(
        <Pagination.Item
          aria-current='page'
          key={number}
          active={number === activePage}
          activeLabel={intl.formatMessage({ id: 'Kuvat.nykyinen' })}
          onClick={() => pageChange(number, false)}>
          {number}
        </Pagination.Item>
      )
    }
    for (let number = 1; number <= nrOfPages; number++) {
      pages2.push(
        <Pagination.Item
          aria-current='page'
          key={number}
          active={number === activePage}
          activeLabel={intl.formatMessage({ id: 'Kuvat.nykyinen' })}
          onClick={() => pageChange(number, true)}>
          {number}
        </Pagination.Item>
      )
    }

    let lastImage = 0
    if (lastImg[activePage - 1] > totalImages) {
      lastImage = totalImages
    } else {
      lastImage = lastImg[activePage - 1]
    }

    return (
      <div
        className='KuvatContainer'
        onKeyUp={(e) => {
          e.preventDefault()
          handleKeyPress(e, selectedCard[0])
        }}>
        <div className='KuvatControls'>
          <Col xs={12} xl={4} className='KuvatControlsCol'>
          {page === 'selaa' ? (
          <Link
            to={params.cat2 ? `/selaa/${params.cat1}` : '/selaa'}
            className='EdellinenButton'>
            <FaArrowLeft /> <FormattedMessage id='Edellinen' />
          </Link>
          ) : null}
            {/* <Pagination hidden={(images?.length && buttons && !buttons.length) || !images?.length}>
              <Pagination.Prev
                aria-label={intl.formatMessage({ id: 'Edellinen' })}
                id='pagination-prev'
                disabled={activePage === 1}
                key='prev'
                onClick={() => {
                  pageChange(activePage - 1, false)
                }}
              />
              <Pagination.Ellipsis hidden={paginationInfo[0]} disabled />
              {pages[paginationInfo[1]]}
              {pages[paginationInfo[2]]}
              {pages[paginationInfo[3]]}
              {pages[paginationInfo[4]]}
              {pages[paginationInfo[5]]}
              <Pagination.Ellipsis hidden={paginationInfo[6]} disabled />
              <Pagination.Next
                aria-label={intl.formatMessage({ id: 'Seuraava' })}
                id='pagination-next'
                disabled={activePage === nrOfPages}
                key='next'
                onClick={() => {
                  pageChange(activePage + 1, false)
                }}
              />
            </Pagination> */}
          </Col>
          <Col xs={12} xl={4} className='KuvatControlsCol'>
            <p hidden={(images && images.length && buttons && !buttons.length) || !images?.length}>
            <FormattedMessage id='Sivu' /> {activePage}: <FormattedMessage id='Kuvat.kuvat' /> {firstImg[activePage - 1]} - {lastImage} / {totalImages}
            </p>
          </Col>
          <Col xs={0} xl={4} className='KuvatControlsCol'>
            {loading || !buttons?.length ? null :
            <Form className='sortMethodForm'>
              <label htmlFor='sort-method'><FormattedMessage id='Kuvat.jarjesta' />:</label>
              <Form.Select
                id='sort-method'
                name='sort-method'
                value={sortMethod}
                onChange={handleSortMethodChange}
                aria-label={intl.formatMessage({ id: 'Kuvat.jarjesta' })}
              >
                {page === 'haku' ? <option value="score">{intl.formatMessage({ id: 'Kuvat.hakutulos' })}</option> : null}
                <option value="alphabet">{intl.formatMessage({ id: 'Kuvat.aakkosjarjestys' })}</option>
                <option value="alphabet-reversed">{intl.formatMessage({ id: 'Kuvat.aakkosjarjestysKaanteinen' })}</option>
              </Form.Select>
            </Form>}
          </Col>
        </div>
        <div className='Kuvat'>
          {getButtons((activePage - 1) * cardsPerPage)}
          {!loading && images && !images.length ?
            <div className='noResults'>
              {page === 'selaa' ?
              null
              :
              <>
                <FaSearch className='SearchIcon' />
                <FormattedMessage id='Haku.eiTuloksia' />
              </>}
            </div> : null}
          {images && images.length && buttons && !buttons.length ? (
            <p style={{ margin: '20px auto' }}>
              <FormattedMessage id='Kuvat.rajauksellaEiTuloksia' />
            </p>
          ) : null}
          <Modal
            show={show}
            onHide={handleHide}
            className='ModalClass'
            aria-hidden='false'
            aria-label={selectedCard[1]}>
            <Modal.Header closeButton closeLabel='Sulje'>
              <Modal.Title id='ModalTitle'>
                <h3>{selectedCard[1]}</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='modalImage'>
                {selectedCard[5] === 'Viittomavideo' ? (
                  <iframe
                    width='700'
                    height='400'
                    src={selectedCard[2]}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen></iframe>
                ) : (
                  <img src={selectedCard[2]} alt={selectedCard[1]} />
                )}
              </div>
              <div className='modalNavigation'>
                <Button
                  variant='light'
                  aria-label={intl.formatMessage({ id: 'EdellinenKuva' })}
                  className='previousButton'
                  tabIndex='0'
                  onClick={() => nextImage(selectedCard[0], false)}>
                  <FaArrowLeft id='nextImage' />
                </Button>
                <Button
                  variant='light'
                  aria-label={intl.formatMessage({ id: 'SeuraavaKuva' })}
                  className='nextButton'
                  id='nextButton'
                  tabIndex='0'
                  onClick={() => nextImage(selectedCard[0], true)}>
                  <FaArrowRight id='nextImage' />
                </Button>
              </div>
            </Modal.Body>
            <Modal.Body id='body2'>
              <p>© {selectedCard[3]}</p>
              {selectedCard[5] === 'Viittomavideo' ? null : (
                <a href='https://creativecommons.org/licenses/by-nc-sa/3.0/deed.fi' tabIndex='0'>
                  <img
                    src={require('../images/cc.png')}
                    alt='Kuvan lisenssi: Nimeä-EiKaupallinen-JaaSamoin 3.0 Ei sovitettu (CC BY-NC-SA 3.0)'
                    id='cc'
                  />
                </a>
              )}
                <Button
                className='LataaKuva'
                onClick={() => {
                  analyticsClickEvent(selectedCard[1], selectedCard[2])
                  downloadResource(selectedCard[2], selectedCard[6])
                }}
                style={{textDecoration: 'none'}}
                >
                  <span>
                    <FormattedMessage id='Kuvat.lataaKuva' />
                  </span>
                </Button>
            </Modal.Body>
          </Modal>
        </div>

        <div
          className='KuvatControls'
          style={{ marginBottom: '15px' }}
          hidden={(images && images.length && buttons && !buttons.length) || !images?.length}>
          <Col xs={0} xl={4} className='KuvatControlsCol'></Col>

          <Col xs={12} xl={4} className='KuvatControlsCol'>
            <p hidden={(images && images.length && buttons && !buttons.length) || !images?.length}>
              <FormattedMessage id='Sivu' /> {activePage}: <FormattedMessage id='Kuvat.kuvat' /> {firstImg[activePage - 1]} - {lastImage} / {totalImages}
            </p>
          </Col>

          <Col xs={12} xl={4} className='KuvatControlsCol'>
            <Pagination>
              <Pagination.Prev
                aria-label={intl.formatMessage({ id: 'Edellinen' })}
                id='pagination-prev'
                disabled={activePage === 1}
                key='prev'
                onClick={() => {
                  pageChange(activePage - 1, true)
                }}
              />
              <Pagination.Ellipsis hidden={paginationInfo[0]} disabled />
              {pages2[paginationInfo[1]]}
              {pages2[paginationInfo[2]]}
              {pages2[paginationInfo[3]]}
              {pages2[paginationInfo[4]]}
              {pages2[paginationInfo[5]]}
              <Pagination.Ellipsis hidden={paginationInfo[6]} disabled />
              <Pagination.Next
                aria-label={intl.formatMessage({ id: 'Seuraava' })}
                id='pagination-next'
                disabled={activePage === nrOfPages}
                key='next'
                onClick={() => {
                  pageChange(activePage + 1, true)
                }}
              />
            </Pagination>
          </Col>

        </div>
      </div>
    )
  }
}

export default Kuvat
