// React
import { useEffect, useState } from 'react'

// Routing
import { useLocation, useParams } from 'react-router-dom'

// Localization
import { FormattedMessage } from 'react-intl'

// Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react'

// Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

// Components
import Valilehdet from '../components/Valilehdet'
import Kuvat from '../components/Kuvat'
import Hakukentta from '../components/Hakukentta'
import Kuvatyypit from '../components/Kuvatyypit'
import KuvatyypitMobiili from '../components/KuvatyypitMobiili'

// Functions
import { fetchData } from '../utils/functions'

// Viittomavideoiden data
const jsonViittomavideot = require('../utils/viittomavideot.json')

const HaeKuvia = (props) => {
  const { updateHaeURL, lang } = props
  const location = useLocation()
  const params = useParams()
  const { trackEvent } = useMatomo()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [title, setTitle] = useState('')
  const [prevLocation, setPrevLocation] = useState('')

  const tuoViittomavideot = (data, input) => {
    const matchesFromVideos = jsonViittomavideot.filter((vid) =>
      vid.search_phrases.some((phrase) => phrase.includes(input.toLowerCase()))
    )
    if (matchesFromVideos && matchesFromVideos.length) {
      data.images = data.images.concat(matchesFromVideos)
    }
    return data
  }

  useEffect(() => {
    if (location.pathname !== prevLocation && params.input) {
      setPrevLocation(location.pathname)
      updateHaeURL(location.pathname)
      setLoading(true)
      fetchData('haku', lang, params.input).then((res) => {
        if (res.success) {
          if (params.input) {
            setTitle(params.input)
            trackEvent({ category: 'Haku', action: `Hakusana (${params.input})` })
          } else {
            setTitle('')
          }
          setData(tuoViittomavideot(res.data, params.input))
          setLoading(false)
        } else {
          setTitle('')
          setError(res.error)
          setLoading(false)
        }
      })
    }
    //eslint-disable-next-line
  }, [params])

  if (error) {
    return <p>Virhe: {error}</p>
  } else {
    return (
      <Container fluid className='Main'>
        <Valilehdet />
        <Container fluid className='Content'>
          <Row>
            <Col xs={12} xl={9} className='ContentCol'>
              <Kuvatyypit lang={lang} />
              <KuvatyypitMobiili lang={lang} />
              <div className='Hae'>
                <Hakukentta />
              </div>
              <h2 role='alert'>{title ? <><FormattedMessage id='Haku.hakutulokset' /><span>{`: ${title}`}</span></> : null}</h2>
              {!loading && data && data.images ? (
                <Kuvat page='haku' images={data && data.images ? data.images : []} />
              ) : null}
              {loading ? (
                <Spinner animation='border' role='status' style={{ margin: '15px auto' }}>
                  <span className='sr-only'>Ladataan kuvia...</span>
                </Spinner>
              ) : null}
            </Col>
            <Col xs={12} xl={3} className='KuvatyypitCol'>
              <Kuvatyypit lang={lang} />
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default HaeKuvia
