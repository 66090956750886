// React
import { useState } from 'react'

// Routing
import { useNavigate } from 'react-router-dom'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Bootstrap
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

// Styling
import '../styles/Hakukentta.scss'

const Hakukentta = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const [input, setInput] = useState('')
  const [showErrorMsg, setShowErrorMsg] = useState(false)

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (input.length < 2 || input.length > 50) {
      setShowErrorMsg(true)
    } else {
      setShowErrorMsg(false)
      navigate(`/haku/${input}`)
    }
  }

  return (
    <Form onSubmit={handleSubmit} autoComplete='off' pattern='[a-z]{2,50}' id='hakukentta'>
      <Form.Control
        value={input}
        placeholder={intl.formatMessage({ id: 'Haku.kirjoitaHakusana' })}
        onChange={handleChange}
        isInvalid={showErrorMsg}
      />

      <Button type='submit' onClick={handleSubmit} variant='secondary' id='searchButton'>
        <FormattedMessage id='Haku.hae' />
      </Button>

      <Form.Control.Feedback type='invalid'>
        {
          input?.length > 50
          ? <FormattedMessage id='Haku.hakusananPituusMax' />
          : <FormattedMessage id='Haku.hakusananPituusMin' />
        }
      </Form.Control.Feedback>
    </Form>
  )
}

export default Hakukentta
