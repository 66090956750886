// Routing
// import { Link, useLocation } from 'react-router-dom'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styling
import logoPapunet from '../images/Papunet.png'
import { FaBullseye, FaInfoCircle } from 'react-icons/fa'
import '../styles/Ylapalkki.scss'

const Ylapalkki = (props) => {
  // const location = useLocation()
  const { lang } = props
  const intl = useIntl()
  const papunetURL = lang === 'fi' ? 'https://papunet.net/' : 'https://papunet.net/sv/'
  const tietoaURL = lang === 'fi' ? 'https://papunet.net/kuvatyokalut/kuvapankki/' : 'https://papunet.net/sv/bildverktyg/bildbanken/'

  return (
    <div className='Ylapalkki'>
      <Row className='gx-0'>
        <Col xs={11} xl={6} className='Top1'>
          <a href={papunetURL} title={intl.formatMessage({ id: 'Etusivulle' })}>
            <img src={logoPapunet} id='logo' alt='Papunet logo' height='36' />
          </a>
          <span className='pystyviiva'>|</span>
          <h1 aria-hidden='true'><FormattedMessage id='Kuvapankki' /></h1>
          <h1 style={{display: 'none'}}><FormattedMessage id='Title' /></h1>
        </Col>
        <Col xs={1} xl={6} className='Top2'>
          <a href={papunetURL} className='topLink'>
            <FaBullseye />
            <span><FormattedMessage id='Etusivulle' /></span>
          </a>
          <a href={tietoaURL} className='topLink'>
            <FaInfoCircle />
            <span><FormattedMessage id='Tietoa' /></span>
          </a>
          {/* <Link to='tietoa' className='topLink' hidden={location.pathname === '/tietoa'}>
            <FaInfoCircle />
            <span>
              {' '}
              <FormattedMessage id='Tietoa' />
            </span>
          </Link> */}
        </Col>
      </Row>
    </div>
  )
}

export default Ylapalkki
