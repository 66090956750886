// React
import { useContext, useState } from 'react'
import { StateContext } from '../App'

// Localization
import { FormattedMessage } from 'react-intl'

// Bootstrap
import Button from 'react-bootstrap/Button'

// Functions
import { imageTypes } from '../utils/functions'

// Styling
import { FaBars } from 'react-icons/fa'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import '../styles/Kuvatyypit.scss'

const KuvatyypitMobiili = (props) => {
  const { lang } = props
  const ctx = useContext(StateContext)
  const state = ctx[0]
  const setState = ctx[1]
  const [hidden, setHidden] = useState(true)
  let allTypesAreUnselected = state?.type?.length > 0 ? false : true

  const toggleAll = () => {
    if (allTypesAreUnselected) {
      setState({
        ...state,
        arasaac: true,
        drawing: true,
        kuvako: true,
        mulberry: true,
        photo: true,
        sclera: true,
        sign: true,
        toisto: true,
        viittomavideo: true
      })
    } else {
      setState({
        ...state,
        arasaac: false,
        drawing: false,
        kuvako: false,
        mulberry: false,
        photo: false,
        sclera: false,
        sign: false,
        toisto: false,
        viittomavideo: false
      })
    }
  }

  return (
    <div className='KuvatyypitMobiili'>
      <Button className='toggleButton' onClick={() => setHidden(!hidden)}>
        <FaBars />
        <span>
          <FormattedMessage id='Kuvatyypit.valitse' /> ({state.type.length})
        </span>
      </Button>
      <div className='KuvatyypitButtons' hidden={hidden}>
        <Button className='typeToggler' variant='link' onClick={() => toggleAll()}>
          {allTypesAreUnselected ?
          <FormattedMessage id='Kuvatyypit.toggleAllOn' />
          :
          <FormattedMessage id='Kuvatyypit.toggleAllOff' />
          }
        </Button>
        {imageTypes.map((el) => (
          <Button
            role='checkbox'
            aria-checked={state[el.id]}
            key={el.id}
            hidden={lang === 'se' && el.id === 'viittomavideo'}
            onClick={() => setState({ ...state, [el.id]: !state[el.id] })}>
            {state[el.id] ? (
              <ImCheckboxChecked className='checkBox' />
            ) : (
              <ImCheckboxUnchecked className='checkBox' />
            )}
            <span>{lang === 'fi' ? el.label_fi : el.label_se}</span>
            <img
              src={el.img}
              style={{ borderRadius: '6px' }}
              id='KuvatyyppiKuva'
              alt=''
              width='64'
              height='64'
            />
          </Button>
        ))}
      </div>
    </div>
  )
}

export default KuvatyypitMobiili
