// React
import { useEffect, useState } from 'react'

// Routing
import { Link } from 'react-router-dom'

// Localization
import { FormattedMessage } from 'react-intl'

// Bootstrap
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// Styling
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa'
import '../styles/Kategoriat.scss'

const Kategoriat = (props) => {
  const { children, categoryDetails, params } = props
  const [linkElements, setLinkElements] = useState(null)

  // Tämä kommentoitu pois 27.2.2024,
  // koska poisti kategoriat kokonaan näkyvistä kun kuvatyyppi valintoja vaihtoi
  // useEffect(() => {
  //   setLinkElements(null)
  // }, [params])

  useEffect(() => {
    if (children && children.length) {
      const links = children.map((el, index) => {
        const details = categoryDetails.find((item) => item.cat === el)
        return (
          <Link key={index} to={params.cat1 ? `/selaa/${params.cat1}/${el}` : `/selaa/${el}`}>
            <p>{el}</p>
            {details && details.hasChildren ?
            <FaChevronDown/>
            :
            null}
          </Link>
        )
      })
      setLinkElements(links)
    } else {
      setLinkElements(null)
    }
    // eslint-disable-next-line
  }, [children])

  const prevPageButton = (
    <Link to='/selaa' className='EdellinenButton'>
      <FaArrowLeft />
      <p style={{ marginLeft: '5px' }}>
        <FormattedMessage id='Edellinen' />
      </p>
    </Link>
  )

  return (
    <Container xs={12} className='Kategoriat'>
      {linkElements && !params.cat1 ? (
        <Container xs={12} className='Linkit'>
          {linkElements}
        </Container>
      ) : null}
      {linkElements && params.cat1 ? (
        <>
          <Col className='AlakategoriaLinkit'>{prevPageButton}</Col>
          <Col className='AlakategoriaLinkit'>{linkElements}</Col>
          <Col className='AlakategoriaLinkit'></Col>
        </>
      ) : null}
    </Container>
  )
}

export default Kategoriat
