import axios from 'axios'

// Kuva datan haku Papunetin rajapinnasta
export const fetchData = async (page, lang = 'fi', input = '', cat1 = '', cat2 = '') => {
  const baseUrl = 'https://kuha.papunet.net/api'
  let url
  if (page === 'haku') {
    url = `${baseUrl}/search/all/${input}?lang=${lang}`
  } else if (page === 'selaa' && cat1 && cat2) {
    url = `${baseUrl}/browse/class/${cat1}/${cat2}?lang=${lang}`
  } else if (page === 'selaa' && cat1 && !cat2) {
    url = `${baseUrl}/browse/class/${cat1}?lang=${lang}`
  } else if (page === 'selaa' && !cat1 && !cat2) {
    url = `${baseUrl}/browse/class?lang=${lang}`
  } else {
    return { data: {}, success: false, error: 'Virheelliset argumentit.' }
  }

  try {
    const res = await axios.get(url)
    return { data: res.data, success: true, error: null }
  } catch (error) {
    return { data: {}, success: false, error: error }
  }
}
  // Vanhassa API:ssa vastaus on muotoa ({data}); käytössä oli tämä fetch toteutus:
  // return await fetch(url)
  // .then((res) => res.text())
  // .then((res) => res.substring(1))
  // .then((res) => res.substring(0, res.length - 2))
  // .then((res) => JSON.parse(res))
  // .then((res) => {
  //   return { data: res, success: true, error: null }
  // })
  // .catch((error) => {
  //   return { data: {}, success: false, error: error }
  // })

// Kuvatyypit ja niiden kuvatiedostot
export const imageTypes = [
  { id: 'arasaac', label_fi: 'Arasaac', label_se: 'Arasaac', img: require(`../images/arasaac.png`) },
  { id: 'kuvako', label_fi: 'KUVAKO', label_se: 'KUVAKO', img: require(`../images/kuvako.png`) },
  { id: 'mulberry', label_fi: 'Mulberry', label_se: 'Mulberry', img: require(`../images/mulberry.png`) },
  { id: 'drawing', label_fi: 'Piirroskuva', label_se: 'Ritade bilder', img: require(`../images/drawing.png`) },
  { id: 'sclera', label_fi: 'Sclera', label_se: 'Sclera', img: require(`../images/sclera.png`) },
  { id: 'toisto', label_fi: 'Toisto', label_se: 'Toisto', img: require(`../images/toisto.png`) },
  { id: 'photo', label_fi: 'Valokuva', label_se: 'Foto', img: require(`../images/photo.jpg`) },
  { id: 'sign', label_fi: 'Viittomakuva', label_se: 'Ritade tecken', img: require(`../images/sign.png`) },
  { id: 'viittomavideo', label_fi: 'Viittomavideo', label_se: 'Cue video', img: require(`../images/viittomavideo.png`) },
]
