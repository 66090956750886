// React
import { useContext, useEffect, useState } from 'react'
import { StateContext } from '../App'

// Routing
import { Link, useLocation } from 'react-router-dom'

// Localization
import { FormattedMessage } from 'react-intl'

// Styling
import '../styles/Valilehdet.scss'

const Valilehdet = () => {
  const location = useLocation()
  const ctx = useContext(StateContext)
  const state = ctx[0]
  const { haeURL, selaaURL } = state
  const [active, setActive] = useState('selaa')

  useEffect(() => {
    if (location.pathname.substring(0, 6) === '/selaa') {
      setActive('selaa')
    } else {
      setActive('haku')
    }
  }, [location])

  return (
    <div className='tabLine' role='tablist'>
      <Link to={haeURL} role='tab' aria-selected={active === 'haku' ? 'true' : 'false'}>
        <div className={active === 'haku' ? 'activeTab' : 'notActiveTab'}>
          <span>
            <FormattedMessage id='Haku.haeKuvia' />
          </span>
        </div>
      </Link>
      <Link to={selaaURL} role='tab' aria-selected={active === 'selaa' ? 'true' : 'false'}>
        <div className={active === 'selaa' ? 'activeTab' : 'notActiveTab'}>
          <span>
            <FormattedMessage id='Selaa.selaaKuvia' />
          </span>
        </div>
      </Link>
    </div>
  )
}

export default Valilehdet
