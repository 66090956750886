import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

Math.trunc =
  Math.trunc ||
  function (x) {
    if (isNaN(x)) {
      return NaN
    }
    if (x > 0) {
      return Math.floor(x)
    }
    return Math.ceil(x)
  }

const host = window.location.hostname

const isProduction = host === 'kuvapankki.papunet.net' || host === 'bildbank.papunet.net' ? true : false

const matomoInstance = createInstance({
  urlBase: 'https://stats.kvl.fi',
  siteId: host === 'kuvapankki.papunet.net' ? 15 : 20, // 15 suomi, 20 ruotsi
  disabled: isProduction ? false : true,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MatomoProvider value={matomoInstance}>
        <App />
      </MatomoProvider>
    </BrowserRouter>
  </React.StrictMode>
)
